import { Advertiser } from './advertiser'
import { Base, Ref } from './base'
import { Campaign } from './campaign'
import { Creator } from './creator'
import { SocialNetworkKey, ProfileType, DeliverableOptions, DeliverableOptionsKey } from './profile'
import { PaymentStatus } from './payment-method'
import { MediaContent } from './media'
import { CreatorShippingAddress } from './creator'
import { DeliverableMediumKey } from '@modules/deliverables/utils'

export type PostType = 'text' | 'image' | 'video'

export type ProposalTemplateDeliverable = {
  platform: DeliverableMediumKey
  postType: DeliverableOptions
  caption?: string
  content?: string
  exactCaption?: boolean
  hashtags: string[]
  taggedAccounts: string[]
  exampleMedia: MediaContent[]
  exampleLinks: ProposalDeliverableLink[]
  compliance: ProposalDeliverableCompliance[]
  linkInCaption?: ProposalDeliverableLink
}

export type BulkProposalDeliverable = ProposalTemplateDeliverable & {
  id: string
}

export interface ProposalTemplate extends Base {
  org: string
  campaign: string
  name: string
  description?: string
  shippingProduct?: boolean
  deliverablesStartAt?: Date | string | null
  deliverablesEndAt?: Date | string | null
  deliverables: ProposalTemplateDeliverable[]
}

export interface BulkProposalSummary {
  eligibleCreatorsCount: number
  selectedCreatorsCount: number
  estimatedProductValue: number
  deliverableRollups: DeliverableRollup[]
}

export interface BulkProposalRates {
  type: BulkProposalRateType
  deliverableOptions?: Pick<DeliverableRollup, 'platform' | 'postType' | 'offerPrice'>[]
  estimatedProductValue?: number
}

export interface BulkProposal extends ProposalTemplate {
  org: string
  campaign: string
  advertiser: string
  name: string
  paymentMethod?: string | null
  proposalTemplate: Ref<ProposalTemplate> | null
  rates: BulkProposalRates
  status: ProposalStatusEnum.draft | ProposalStatusEnum.completed
  deliverables: BulkProposalDeliverable[]
  summary?: BulkProposalSummary
  hasDiff?: boolean
}

export type BulkProposalUpdate = Partial<Omit<BulkProposal, 'deliverables'>> & {
  deliverables: Partial<BulkProposalDeliverable>[]
}

export interface BulkProposalPublishResponse {
  bulkProposal: BulkProposal
  success: boolean
}

export type BulkProposalCreatorRollupRates = Record<
  SocialNetworkKey,
  Record<DeliverableOptionsKey, number | undefined>
>

export interface BulkProposalCreatorRollup {
  id: string
  proposal: string
  creator: string
  slug: string | undefined
  name: string
  image: string | undefined
  totalReach: number
  rates: BulkProposalCreatorRollupRates
  eligibility: ProposalEligibility
  selected: boolean
  deliverableRollups: DeliverableRollup[]
}

export enum ProposalEligibility {
  eligible = 'eligible',
  partiallyEligible = 'partiallyEligible',
  notEligible = 'notEligible'
}

export enum BulkProposalRateType {
  suggestedRate = 'suggestedRate',
  sameRate = 'sameRate',
  shippingProduct = 'shippingProduct'
}

export interface ProposalDeliverableLink {
  url: string
}

export enum ProposalDeliverableCompliance {
  ad = 'ad',
  paidPartnership = 'paid_partnership',
  sponsored = 'sponsored'
}

export enum ProposalTimelineOptions {
  dateRange = 'dateRange',
  onExactDate = 'onExactDate'
}

export interface CreatorProof {
  verified?: boolean
  links?: ProposalDeliverableLink[]
  media?: MediaContent[]
}

export interface DeliverableFieldRevision {
  path: string
  previousValue: any
  updatedValue: any
  updatedAt: string
}

export interface DeliverableRollup {
  platform: DeliverableMediumKey
  postType: DeliverableOptions
  quantity: number
  offerPrice: number
  available: boolean
}

export interface ProposalDeliverable {
  id: string
  platform: DeliverableMediumKey
  postType: DeliverableOptions
  content: string
  caption: string
  exactCaption?: boolean
  hashtags: string[]
  taggedAccounts: string[]
  exampleMedia: MediaContent[]
  exampleLinks: ProposalDeliverableLink[]
  linkInBio?: ProposalDeliverableLink
  linkInCaption?: ProposalDeliverableLink
  linkOnContent?: ProposalDeliverableLink
  compliance: ProposalDeliverableCompliance[]
  lastRevisedFields: DeliverableFieldRevision[]
  offerPrice: number
  audienceReach: number
  status: ProposalDeliverableStatus
  creatorProof?: CreatorProof
}

export type ShallowDeliverable = Omit<ProposalDeliverable, 'status' | 'id' | 'creatorProof'>

// defined here to aovid circular dependency in ./proposal-message.ts
export enum ProposalMessageStatus {
  targetRead = 'target_read',
  targetUnread = 'target_unread'
}

export type ProposalMessagePreview = {
  body: string
  target: string
  media: string[]
  message: string
  status: ProposalMessageStatus | null
  sentAt: string
}

export enum ProposalStatusEnum {
  prospect = 'prospect',
  draft = 'draft',
  pending = 'pending',
  target_accepted = 'target_accepted',
  target_rejected = 'target_rejected',
  awaiting_deliverables = 'awaiting_deliverables',
  completed = 'completed',
  expired = 'expired',
  refunded = 'refunded'
}

export type ProposalStatus =
  | 'prospect'
  | 'draft'
  | 'pending'
  | 'target_accepted'
  | 'target_rejected'
  | 'awaiting_deliverables'
  | 'completed'
  | 'expired'
  | 'refunded'
// | 'archived' :: this status does exist on the backend, but we have no use for it on the frontend as we shouldn't be displaying or fetching archived proposals

export interface Proposal extends Base {
  name: string
  org: string
  campaign: Campaign
  campaignTarget: ProfileType
  advertiser: Advertiser
  creator: Creator
  bulkProposal: {
    id: string | null
    eligibility: ProposalEligibility
    selected: boolean
    deliverableRollups: DeliverableRollup[]
  }
  status: ProposalStatus
  deliverables: ProposalDeliverable[]
  shippingProduct?: boolean
  shippingAddress?: CreatorShippingAddress
  timeline?: ProposalTimelineOptions
  deliverablesStartAt?: string
  deliverablesEndAt?: string
  lastMessage?: {
    creator?: ProposalMessagePreview
    advertiser?: ProposalMessagePreview
    all?: ProposalMessagePreview
  }
  initialMessage?: ProposalMessagePreview
  href?: string
  description?: string
  paymentMethod?: string
  charge?: string
  payout?: string
  chargeStatus?: PaymentStatus
  payoutStatus?: PaymentStatus
}

export type ProposalDeliverableStatus =
  | 'pending'
  | 'target_awaiting_review'
  | 'rejected'
  | 'approved'

export enum ProposalDeliverableStatusEnum {
  pending = 'pending',
  target_awaiting_review = 'target_awaiting_review',
  rejected = 'rejected',
  approved = 'approved'
}

export interface CampaignProposalMetrics {
  totalPrice: number
  proposalsCount: number
  deliverablesCount: number
}
