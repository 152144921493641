import { Base } from './base'
import { SlugOption } from './common'
import { ProfileStatus } from './profile'

export interface Advertiser extends Base {
  name: string
  image?: string
  description?: string
  org: string
  website?: AdvertiserWebsite
  status: ProfileStatus
  primaryIndustry?: string
  secondaryIndustry?: string
  goals?: SlugOption[]
  targets?: SlugOption[]
  approvalStatus: ApprovalStatus
}

export interface AdvertiserWebsite {
  url: string
}

export interface AdvertiserIndustry extends Base {
  name: string
  slug: string
  parent?: string
  type: 'primary' | 'secondary'
  children?: string[] | AdvertiserIndustry[]
}

// advertiser verification for sending bulk proposals over limit of 50
export enum ApprovalStatus {
  pending = 'pending',
  approved = 'approved',
  blocked = 'blocked',
  banned = 'banned'
}

export type AdvertiserUpdatePayload = Partial<Omit<Advertiser, 'id'>> & { id: Base['id'] }
