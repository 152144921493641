import { default as _slugify } from 'slugify'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import set from 'lodash/set'
import toast from 'react-hot-toast'
import { ApiError } from '@api/utils'
import isURL from 'validator/lib/isURL'
import { siteDomain } from './env'
import { Base } from '@api/types'

export const isServer = typeof window === 'undefined'

export function slugify(
  input: string,
  options: Partial<{
    replacement?: string
    remove?: RegExp
    lower?: boolean
    strict?: boolean
    locale?: string
    trim?: boolean
  }> = { lower: true, trim: true }
) {
  return _slugify(input, options)
}

export async function mapAsync<T>(
  items: Array<T>,
  handler: (i: T) => Promise<unknown>,
  concurrency: number = 1
) {
  let results = []
  let index = 0
  while (index < items.length) {
    // batch items into groups based on size of concurrency (default: 1)
    const batch = items.slice(index, index + concurrency)
    // concurrently run batch with promise.all
    const _results = await Promise.all(batch.map(handler))
    // push batch results to results
    results.push(..._results)
    index += concurrency
  }
  return results
}

const irregularPluralNouns: Record<string, string> = Object.freeze({
  // Same spelling
  deer: 'deer',
  sheep: 'sheep',
  fish: 'fish',
  buffalo: 'buffalo',
  bison: 'bison',
  roof: 'roofs',
  // Changed spelling
  man: 'men',
  woman: 'women',
  child: 'children',
  foot: 'feet',
  tooth: 'teeth',
  goose: 'geese',
  mouse: 'mice',
  person: 'people',
  ox: 'oxen',
  appendix: 'appendices',
  criterion: 'criteria',
  phenomenon: 'phenomena',
  radius: 'radii',
  datum: 'data',
  nucleus: 'nuclei',
  syllabus: 'syllabi',
  addendum: 'addenda',
  corrigendum: 'corrigenda',
  millennium: 'millennia',
  ovum: 'ova',
  spectrum: 'spectra',
  caveman: 'cavemen',
  policeman: 'policemen',
  louse: 'lice',
  penny: 'pence',
  index: 'indices',
  // 'f'/'fe' -> 'ves' exceptions
  belief: 'beliefs',
  chef: 'chef',
  chief: 'chief',
  hoof: 'hooves',
  // 'o' -> 'oes' exceptions
  photo: 'photos',
  video: 'videos'
})

/**
 * Returns a plural of the given noun if the given count is 0 or greater than 1.
 * If a known plural version of the noun isn't provided, the function will guess based on the last letter.
 * Nouns ending in 'y' will end in 'ies' in place of the 'y' character, and
 * all other nouns will have 's' appended to the original noun. Only relevant to English
 *
 * @param opts { noun: string, count: number, plural?: string}
 * @returns string
 */
export function getPlural(opts: { noun: string; count?: number; plural?: string }): string {
  const { noun, count = 0, plural } = opts
  const lowercaseNoun = noun.toLowerCase()
  if (count === 1 || count < 0 || noun.length < 1) return noun
  if (plural && count !== 1) return plural

  const suffixedNoun = ({ suffix, trim = 0 }: { suffix: string; trim?: number }) =>
    `${noun.slice(0, noun.length - trim)}${suffix}`

  switch (true) {
    // Has an irregular plural form that doesn't match a rule
    case lowercaseNoun in irregularPluralNouns:
      const plural = irregularPluralNouns[lowercaseNoun]
      const pluralCaseCorrected = noun[0] + plural.substring(1)
      return pluralCaseCorrected
    // ends in consonant+y -> replace consonant+ies
    case /[^aeiou]y$/i.test(noun):
      return suffixedNoun({ trim: 1, suffix: 'ies' })
    // ends in 'is' -> replace with 'es'
    case /(is)$/i.test(noun):
      return suffixedNoun({ suffix: 'es', trim: 2 })
    // ends in 's', 'x', 'z', 'sh', or 'ch' -> append 'es'
    case /[sxz(sh)(ch)]$/i.test(noun):
      return suffixedNoun({ suffix: 'es' })
    // ends in 'f' or 'fe' but not 'ff' or 'of' -> replace with 'ves'
    case /([^fo]f|fe)$/i.test(noun):
      const endingMatch = [...(noun.match(/(f|fe)$/i) ?? [])][0]
      return suffixedNoun({ trim: endingMatch.length, suffix: 'ves' })
    // ends in 'o' but not 'oo' -> append 'es'
    case /([^o]o)$/i.test(noun):
      return suffixedNoun({ suffix: 'es' })
    // default behavior is to just append 's'
    default:
      return suffixedNoun({ suffix: 's' })
  }
}

export function toFixed(value: number, fractionDigits: number): string {
  const text = value.toFixed(fractionDigits)
  return fractionDigits > 0 ? text.replace(/\.?0+$/gi, '') : text
}

export function formatPhoneNumber(value: string) {
  let inputValue = value.replace(/\D/g, '')
  const inputLength = inputValue.length
  if (inputLength > 10) {
    const countryCode = inputValue.substring(0, inputLength - 10)
    const areaCode = inputValue.substring(inputLength - 10, inputLength - 7)
    const first3 = inputValue.substring(inputLength - 7, inputLength - 4)
    const last4 = inputValue.substring(inputLength - 4, inputLength)
    inputValue = `+${countryCode} (${areaCode}) ${first3}-${last4}`
  } else {
    const areaCode = inputValue.substring(0, 3)
    const first3 = inputValue.substring(3, 6)
    const last4 = inputValue.substring(6, 10)
    if (inputValue.length > 6) inputValue = `(${areaCode}) ${first3}-${last4}`
    else if (inputValue.length > 3) inputValue = `(${areaCode}) ${first3}`
    else if (inputValue.length > 0) inputValue = `(${areaCode}`
  }

  return inputValue
}

export function formatDate(
  value: Date | string,
  format: string | DateTimeFormatOptions,
  options?: { zone?: string }
): string {
  const date = DateTime.fromJSDate(new Date(value), options)

  if (typeof format === 'string') {
    return date.toFormat(format)
  }
  return date.toLocaleString(format)
}

export function flattenObject(
  _obj: Record<string, any>,
  _path: string[] = []
): Record<string, any> {
  const _flattenObject = (obj: Record<string, any>, path: string[]): Record<string, any> => {
    return !isPlainObject(obj)
      ? { [path.join('.')]: obj }
      : Object.entries(obj).reduce(
          (acc, [key, value]) => merge(acc, _flattenObject(value, [...path, key])),
          {}
        )
  }
  return _flattenObject(_obj, _path)
}

export function nestObject(_obj: Record<string, any>, _path: string[] = []): Record<string, any> {
  return Object.entries(_obj).reduce((acc, [key, value]) => set(acc, key, value), {})
}

export function getId<T extends Base>(item: T) {
  return item.id
}

export function handleApiError(err: ApiError, defaultMessage?: string) {
  toast.error(err?.message ?? defaultMessage)
}

export async function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

type NullishStrNum = string | number | null | undefined

export function parseNumber(defaultVal?: NullishStrNum) {
  return (val: string): NullishStrNum => {
    return val === '' ? defaultVal : val?.replace(/[^0-9.]/g, '')
  }
}

export function parseCurrencyFloat(defaultVal?: NullishStrNum) {
  return (val: string): NullishStrNum => {
    return !val ? defaultVal : Number(val?.replace(/(\$|,)/g, ''))
  }
}

export function parsePhoneNumber(defaultVal?: NullishStrNum) {
  return (val: string): NullishStrNum => {
    return val === '' ? defaultVal : val?.replace(/[^0-9.+() \-]/g, '')
  }
}

export function sanitizePhoneNumber(num: string) {
  return num?.replace(/\D/g, '')
}

// lets not get too complex here - just strip out all non-numeric characters and validate length
// phone numbers must be at least 10 characters and no more than 15 per https://en.wikipedia.org/wiki/Telephone_numbering_plan
export function isValidPhoneNumber(p: string): boolean {
  if (!p) return false
  const sanitizedNum = sanitizePhoneNumber(p)
  return sanitizedNum.length >= 10 && sanitizedNum.length < 15
}

export function getAgeInYears(date: string): number {
  return Math.floor(Math.abs(DateTime.fromISO(date, { zone: 'utc' }).diffNow('years').years))
}

export const retriable = async (
  task: () => void,
  maxAttempts = 5,
  retryDelay = 2000,
  attempt = 0
): Promise<void> => {
  // Delay request if second attempt
  await delay(attempt * retryDelay)

  try {
    return await task()
  } catch (err: any) {
    // Re-throw error if we receive a 4xx
    if ((err.status && err.status < 500) || (err.response && err.response.status < 500)) {
      throw err
    }
    // Re-throw error if we reached max number of attempts
    if (attempt > maxAttempts) {
      throw err
    }
    // Retry task
    return retriable(task, maxAttempts, retryDelay, attempt + 1)
  }
}

let units = new Map([
  [0, ''],
  [1, 'K'],
  [2, 'M'],
  [3, 'B'],
  [4, 'T']
])

function log(value: number, base: number = 10) {
  return Math.log(value) / Math.log(base)
}

export function toShortNum(num: number) {
  if (num < 1000) {
    return `${num}`
  }
  let n = Math.floor(log(num, 1000))
  let unit = units.get(n) ?? ''
  let [whole, decimal] = `${num / Math.pow(1000, n)}`.split('.')
  let m = whole
  if (whole.length < 2 && decimal && !decimal.startsWith('0')) {
    m = `${whole}.${decimal.slice(0, 1)}`
  }
  return `${m}${unit}`
}

export function formatExpiration(date: string | undefined) {
  if (!date) return null
  return DateTime.fromISO(date).toFormat('LL/yy')
}

export const isMobile = (): boolean => {
  if (isServer) return false
  let check = false
  const a = navigator.userAgent || navigator.vendor
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substring(0, 4)
    )
  )
    check = true
  return check
}

export function numberLocaleString(val: number, fixedDigits: number = 2): string {
  return Number(toFixed(val, fixedDigits)).toLocaleString()
}

export function formatCurrency(value: number | string, currency: string = 'USD') {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2, // requires at least 2 digits, 2500.1 would be $2,500.10
    maximumFractionDigits: 2 // constrains to a max of 2 digits 2500.111 would be $2,500.11
  })

  return formatter.format(typeof value === 'string' ? Number(value) : value) /* $2,500.00 */
}

export function formatUSD(value: number | string) {
  return formatCurrency(value, 'USD')
}

const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/

export function matchUrl(str: string) {
  return str.matchAll(urlRegex)
}

export function prefixUrl(value: string): string {
  try {
    if (!/^(http|https):\/\//.test(value)) {
      const prefixedUrlString = new URL(`http://${value}`).href
      if (isURL(prefixedUrlString)) return prefixedUrlString
    }
  } catch {
    //ignore
  }
  return value
}

export function copyText(value: string) {
  navigator.clipboard.writeText(value)
}

export function isoString(date: string | Date) {
  return date instanceof Date ? date.toISOString() : new Date(date).toISOString()
}

export function creatorUrl(slug: string) {
  return `${siteDomain}/c/${slug}`
}

export function shortId() {
  return Math.random().toString(16).split('.')[1]
}

export const noop = () => {}

export function createLookup<T extends Record<string, any>>(opts: {
  of: T[]
  by: keyof T
}): Record<string, T> {
  const objects = opts.of
  const idKey = opts.by
  return objects.reduce((acc, cur) => {
    acc[cur[idKey]] = cur
    return acc
  }, {} as Record<string, T>)
}

export function createGroupedLookup<T extends Record<string, any>>(props: {
  of: T[]
  groupIds: string[]
  matchingProperty: string
}): Record<string, T[]> {
  const objects = props.of
  const idKey = props.matchingProperty
  return props.groupIds.reduce((acc, cur) => {
    acc[cur] = objects.filter((child) => child[idKey] === cur)
    return acc
  }, {} as Record<string, T[]>)
}

export function applyDateInputMask(value: string, type: 'date' | 'month' | 'year'): string {
  const dateTypeFormats: Record<string, string> = {
    year: 'yyyy',
    month: 'MM/yyyy',
    date: 'MM/dd/yyyy'
  }
  const format = dateTypeFormats[type]

  const filteredCharacters = value
    .split('')
    .filter((val: string) => /[0-9]|[\/]/.test(val))
    .slice(0, format.length)

  let formatIndex = 0
  let inputIndex = 0
  while (inputIndex < filteredCharacters.length) {
    const prevSlotFormat = format[formatIndex - 1]
    const slotFormat = format[formatIndex]
    const nextSlotFormat = format[formatIndex + 1]

    const prevCharacter = filteredCharacters[inputIndex - 1]
    const inputCharacter = filteredCharacters[inputIndex]

    if (slotFormat === '/' && inputCharacter !== '/') {
      filteredCharacters.splice(inputIndex, 0, '/')
      continue
    } else if (slotFormat === 'M' && nextSlotFormat === 'M') {
      if (Number(inputCharacter) > 1) {
        filteredCharacters.splice(inputIndex, 0, '0')
        continue
      }
    } else if (slotFormat === 'M' && prevSlotFormat === 'M') {
      if (Number(prevCharacter) > 0 && Number(inputCharacter) > 2) {
        filteredCharacters.splice(inputIndex - 1, 0, '0')
        continue
      }
    } else if (slotFormat === 'd' && nextSlotFormat === 'd') {
      if (Number(inputCharacter) > 3) {
        filteredCharacters.splice(inputIndex, 0, '0')
        continue
      }
    } else if (slotFormat === 'd' && prevSlotFormat === 'd') {
      if (Number(prevCharacter) > 2 && Number(inputCharacter) > 1) {
        filteredCharacters.splice(inputIndex - 1, 0, '0')
        continue
      }
    }
    // filteredCharacters.push(filteredCharacters[inputIndex])
    formatIndex++
    inputIndex++
  }

  const sanitizedValue = filteredCharacters.join('')
  return sanitizedValue
}

export const mimeTypeExtensions: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/pdf': 'pdf',
  'application/zip': 'zip'
}

export function scrollToElement(htmlNode: HTMLElement) {
  let offset = 100
  const navEl = document.getElementById('main-navigation')

  if (navEl) offset += navEl.offsetHeight

  const y = htmlNode.getBoundingClientRect().top + window.scrollY - offset

  window.scrollTo({ top: y, behavior: 'smooth' })
}

export function dayDifference(startDate: string, endDate: string): number | undefined {
  if (!startDate || !endDate) return

  const _start = DateTime.fromISO(startDate)
  const _end = DateTime.fromISO(endDate)
  const diff = _end.diff(_start, ['days']).toObject()?.days

  return diff && Math.round(Math.abs(diff))
}

export function truncateStringByCharCount(string: string, maxWords: number) {
  if (string.length <= maxWords) return string

  const truncatedString = string.slice(0, maxWords)

  return truncatedString + '...'
}
