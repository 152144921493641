import { Base, Ref } from './base'
import { College } from './college'
import { Sport } from './sport'

export interface SportsTeam extends Base {
  name: string
  league: SportsTeamLeague
  sport: Ref<Sport>
  college?: Ref<College>
}

// allows us to easily add professional leagues in the future
export enum SportsTeamLeague {
  ncaa = 'ncaa'
}
