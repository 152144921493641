import { OrgType } from '@barstoolsports/union/lib/twoyay/types/org'
import { Advertiser } from './advertiser'
import type { Base, Ref } from './base'
import { Creator } from './creator'
import { ShippingAddress } from './location'
import { User, UserMode } from './user'

export interface Org extends Base {
  name: string
  owner: Ref<User>
  image?: string | null
  phoneNumber: string
  address: OrgAddress
  type: OrgType
  paymentMethodProviders: OrgPaymentMethodProviders
}

export interface OrgPaymentMethodProviders {
  stripe: string
}

export interface OrgAddress extends ShippingAddress {}

export interface OrgMember extends Base {
  user: Ref<User>
  org: Ref<Org>
  access: OrgAccess
}

export interface PopulatedOrgMember extends Omit<OrgMember, 'user' | 'org'> {
  user: User
  org: Org
}

export enum OrgRole {
  admin = 'admin',
  manager = 'manager',
  member = 'member',
  athlete = 'athlete',
  collectiveAdmin = 'collectiveAdmin'
}

export interface OrgAccess {
  role: OrgRole

  advertisers: OrgAdvertiserAccess[]
  creators: OrgCreatorAccess[]
}

export enum ProfileRole {
  manager = 'manager',
  member = 'member'
}

export interface OrgAdvertiserAccess {
  advertiser: string
  role: ProfileRole
}

export interface OrgCreatorAccess {
  creator: string
  role: ProfileRole
}

export type OrgInviteStatus = 'pending' | 'accepted' | 'expired'
export interface OrgInvite extends Base {
  name: string
  email: string
  sender: string
  org: string
  status: OrgInviteStatus
  expiresAt: Date | string
  access: OrgAccess
}

export interface OrgInviteBody {
  // name: string
  email: string
  // access: OrgAccess
}

export interface OrgProfiles {
  advertisers: Advertiser[]
  creators: Creator[]
}

export interface OrgDefaultProfile {
  org: string
  profile: Advertiser | Creator
  profileType: UserMode
}
