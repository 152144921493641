export enum CollegeDivision {
  divisionOne = 'division_one',
  divisionTwo = 'division_two',
  divisionThree = 'division_three'
}

export const CollegeDivisionOptions = [
  { id: CollegeDivision.divisionOne, name: 'Division 1' },
  { id: CollegeDivision.divisionTwo, name: 'Division 2' },
  { id: CollegeDivision.divisionThree, name: 'Division 3' }
]

export const CollegeDivisionNumerals = Object.freeze({
  [CollegeDivision.divisionOne]: 'I',
  [CollegeDivision.divisionTwo]: 'II',
  [CollegeDivision.divisionThree]: 'III'
})

export type { College } from '@barstoolsports/union/lib/twoyay/types/college'
