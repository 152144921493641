import create from 'zustand'
import { persist } from 'zustand/middleware'
import { UseAuthState } from './types'
import { state } from './state'

export const useAuthStore = create<UseAuthState>(
  persist(state, {
    name: 'marketplace.auth',
    getStorage: () => localStorage,
    version: 1
  })
)

export * from './hooks'
