import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { flattenObject, isServer, nestObject } from '@lib/utils'

interface IQueryParamsContext {
  queryParams: Record<string, any>
  updateQueryParams: (nextQuery: Record<string, any>, options?: { append: boolean }) => void
}

const QueryParamsContext = createContext({} as IQueryParamsContext)

export function QueryParamsProvider({ children }: { children: ReactNode }) {
  const router = useRouter()

  const queryParams = useMemo(
    () => {
      if (isServer) return {}

      return nestObject(
        queryString.parse(window.location.search, {
          arrayFormat: 'bracket-separator',
          arrayFormatSeparator: ','
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath]
  )

  const updateQueryParams: IQueryParamsContext['updateQueryParams'] = useCallback(
    (nextQuery, { append } = { append: true }) => {
      const nextQueryString = queryString.stringifyUrl(
        {
          url: `${window.location.origin}${window.location.pathname}`,
          query: { ...(append ? queryParams : {}), ...flattenObject(nextQuery) }
        },
        {
          arrayFormat: 'bracket-separator',
          arrayFormatSeparator: ',',
          skipNull: true,
          skipEmptyString: true,
          encode: false
        }
      )

      router.replace(router.pathname, nextQueryString, { shallow: true })
    },
    [router, queryParams]
  )

  return (
    <QueryParamsContext.Provider value={{ queryParams, updateQueryParams }}>
      {children}
    </QueryParamsContext.Provider>
  )
}

function useQueryParams() {
  return useContext(QueryParamsContext)
}

export default useQueryParams
