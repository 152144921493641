export const appEnv = process.env.NEXT_PUBLIC_APP_ENV ?? process.env.NODE_ENV

export const isProduction = appEnv === 'production'

export const isDevelopment = appEnv !== 'production'

export const testMode = process.env.NEXT_PUBLIC_CYPRESS === 'true'

export const disableRobots = process.env.NEXT_PUBLIC_DISABLE_ROBOTS === 'true'

export const apiHost = process.env.NEXT_PUBLIC_API_HOST ?? 'https://api-dev.twoyay.com'

export const unionHost =
  process.env.NEXT_PUBLIC_UNION_HOST ?? 'https://union-staging.barstoolsports.com/v2'

export const siteDomain = isProduction ? 'https://twoyay.com' : 'https://dev.twoyay.com'

export const segmentToken = process.env.NEXT_PUBLIC_SEGMENT_TOKEN as string

export const venmoEnabled = process.env.NEXT_PUBLIC_VENMO_ENABLED === 'true'

export const bugsnagToken = process.env.NEXT_PUBLIC_BUGSNAG_TOKEN as string | undefined

export const ably = {
  subscribeKey: (process.env.NEXT_PUBLIC_ABLY_KEY as string) ?? 'test-key'
}
