import { Advertiser } from './advertiser'
import { Base, Ref } from './base'
import { Creator } from './creator'
import { Proposal, ProposalMessageStatus } from './proposal'
import { User } from './user'
import { MediaContent } from './media'

export interface ProposalMessage extends Base {
  user: Ref<User>
  proposal: Ref<Proposal>
  body: string
  target: ProposalMessageTarget
  type: ProposalMessageType
  advertiser: Ref<Advertiser>
  creator: Ref<Creator>
  media?: MediaContent[]
  revision?: ProposalRevision
  status: ProposalMessageStatus
  widget?: MessageWidget
  embeds?: MessageEmbed[]
}

export interface PopulatedProposalMessage
  extends Omit<ProposalMessage, 'advertiser' | 'creator' | 'proposal'> {
  proposal: Proposal
  advertiser: Advertiser
  creator: Creator
  temporary?: boolean
  failed?: boolean
}

export interface ProposalRevision {
  key: string
  oldValue: string
  newValue: string
}

export enum ProposalMessageTarget {
  advertiser = 'advertiser',
  creator = 'creator',
  all = 'all'
}

export enum ProposalMessageType {
  direct = 'direct',
  system = 'system',
  revision = 'revision',
  widget = 'widget',
  alert = 'alert'
}

export enum MessageWidgetType {
  initial = 'initial',
  revision = 'revision'
}
export interface MessageWidget {
  proposalSnapshot: Proposal
  type: MessageWidgetType
}

export interface MessageEmbedLink {
  text: string
  url: string
  type: MessageEmbedLinkType
}

export enum MessageEmbedLinkType {
  nilAlert = 'nil_alert',
  addressChangeAlert = 'address_change_alert'
}

export interface MessageEmbedList {
  items: string[]
}

export enum MessageEmbedType {
  link = 'link',
  orderedList = 'orderedList',
}
export interface MessageEmbed {
  id: string
  type: MessageEmbedType
  link?: MessageEmbedLink
  orderedList?: MessageEmbedList
  numberedList?: MessageEmbedList
}
