import { useEffect, useState } from 'react'

export type BreakpointPrefix = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

// Taken from tailwind breakpoints https://tailwindcss.com/docs/responsive-design
const MIN_BREAKPOINTS = Object.freeze({
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)'
})

const MAX_BREAKPOINTS = Object.freeze({
  sm: '(max-width: 639px)',
  md: '(max-width: 767px)',
  lg: '(max-width: 1023px)',
  xl: '(max-width: 1279px)',
  '2xl': '(max-width: 1535px)'
})

const BREAKPOINTS = Object.freeze({
  min: MIN_BREAKPOINTS,
  max: MAX_BREAKPOINTS
})

export function useMediaQuery(query: string, options?: { ssr?: boolean }) {
  const isServer = typeof window === 'undefined'
  const [matches, setMatches] = useState(isServer && options?.ssr ? true : false)

  useEffect(() => {
    if (!isServer) {
      const mediaQuery = window.matchMedia(query)
      setMatches(mediaQuery.matches)
      const mediaQueryHandler = (event: MediaQueryListEvent) => {
        if (matches !== event.matches) setMatches(event.matches)
      }
      mediaQuery.addEventListener('change', mediaQueryHandler)
      return () => {
        mediaQuery.removeEventListener('change', mediaQueryHandler)
      }
    }
  }, [isServer, query, matches])

  return matches
}

export function useBreakpoint({
  size,
  max,
  ssr
}: {
  size: BreakpointPrefix
  max: boolean
  ssr?: boolean
}) {
  const matches = useMediaQuery(BREAKPOINTS[max ? 'max' : 'min'][size], { ssr })
  return matches
}
