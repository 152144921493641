import { Base } from './base'

export type PaymentStatus =
  | 'draft'
  | 'batched'
  | 'pending'
  | 'in_transit'
  | 'queued'
  | 'paid'
  | 'failed'
export interface PaymentMethod extends Base {
  org: string
  type: PaymentMethodType
  provider: PaymentMethodProvider
  providerId: string
  default: boolean
  card?: PaymentMethodCard
  bankAccount?: PaymentMethodBankAccount
  customer?: PaymentMethodCustomer
}

export enum PaymentMethodType {
  card = 'card',
  bankAccount = 'bank_account',
  invoice = 'invoice'
}

export enum PaymentMethodProvider {
  stripe = 'stripe'
}

export interface PaymentMethodCard {
  brand: string
  last4: string
  expiresAt: string
}

export interface PaymentMethodBankAccount {
  bankName: string
  last4: string
  routingNumber: string
}

export interface PaymentMethodCustomer {
  name: string
  email: string
  providerCustomer: Record<string, any>
}

export interface ChargeSummary {
  user: string
  amount: number
  status: PaymentStatus
  org: string
  paymentMethod: { brand: string, last4: string }
  card?: { brand: string, last4: string }
  bankAccount?: { bankName: string, last4: string }
  invoice?: { email: string, invoiceUrl?: string }
  sentAt?: Date
}
