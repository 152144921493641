import { SocialRollup } from './social'
import { Profile, ProfileStatus } from './profile'
import { College, CollegeDivision } from './college'
import { Sport } from './sport'
import { LatLngLiteral, ShippingAddress } from './location'
import { SportsTeam, SportsTeamLeague } from './sports-team'
import { Base } from './base'

export type CreatorOnboardingEvent =
  | 'signed_up'
  | 'social_linked'
  | 'payout_added'
  | 'profile_published'

export enum PublicProfileStatus {
  active = 'active',
  hidden = 'hidden'
}

export interface CreatorSearchLocation extends LatLngLiteral {
  formattedAddress: string
  city: string
  state: string
  country?: string
  postalCode?: string
}

export interface Creator extends Omit<Profile, 'rollup'> {
  rollup: SocialRollup
  slug?: string
  birthdate?: string
  onboarding: CreatorOnboardingEvent[]
  status: ProfileStatus
  publishStatus: PublicProfileStatus
  interests: string[]
  isCollegeAthlete?: boolean
  collegeAthlete?: CreatorAthlete
  searchLocation?: CreatorSearchLocation
  shippingAddress?: CreatorShippingAddress
  phoneNumber?: string
}

export interface CreatorShippingAddress extends ShippingAddress {
  name: string
}

export enum AthleteStatus {
  active = 'active',
  transferred = 'transferred',
  graduated = 'graduated',
  proEarly = 'pro_early',
  leftEarly = 'left_early',
  traded = 'traded',
  retired = 'retired',
  deleted = 'deleted'
}

export interface CreatorAthlete {
  id: string
  name?: string
  collective?: string
  startDate?: Date
  endDate?: Date
  expectedGraduationDate?: string
  isBarstoolAthlete?: boolean
  team?: SportsTeam
  league?: SportsTeamLeague
  sports: Sport[]
  college?: College
  division?: CollegeDivision
  email: string
  tags?: string[]
  notes?: string
  merchLink?: string
  verified?: boolean
  status?: AthleteStatus
}

export interface CreatorAthleteUpdate {
  name?: string
  collective?: string
  email?: string
  league?: SportsTeamLeague
  division?: CollegeDivision
  college?: string
  sport?: string[]
  expectedGraduationDate?: string
  isBarstoolAthlete?: boolean
  verified?: boolean
  status?: AthleteStatus
}

export interface CreatorInterest extends Partial<Base> {
  name: string
  parentName: string | null
  id: string
  level?: number
}

export interface CreatorGroupedInterest extends CreatorInterest {
  children: CreatorInterest[] | CreatorGroupedInterest[]
}
