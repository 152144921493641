import { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { disableRobots, segmentToken, testMode } from '@lib/env'

import 'react-dates/lib/css/_datepicker.css'
import '@styles/globals.css'

import Notifications from '@components/notifications'
import { AdBlockModal } from '@ui/components/ad-block-modal'
import { usePageView, useSegmentIdentification } from '@hooks/analytics'
import { BugsnagProvider } from '@lib/bugsnag'
import { QuantcastMeasureScript, QuantcastChoiceScript } from '@lib/quantcast'
import { DefaultSeo } from 'next-seo'
import { useShowAdBlockModal } from '@hooks/show-ad-block-modal'
import { QueryParamsProvider } from '@hooks/query-params'
import { useSyncSegment } from '@barstoolsports/web-sdk'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

if (process.env.NEXT_PUBLIC_MOCKING_ENABLED === 'true') {
  import('mocks').then(({ activateMocks }) => activateMocks())
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  usePageView()
  useSegmentIdentification()
  useSyncSegment(segmentToken)

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page)

  const showAdBlockModal = useShowAdBlockModal()

  return (
    <BugsnagProvider>
      <QueryParamsProvider>
        <DefaultSeo
          title='TwoYay: Influencer Marketplace & Creator Partnership Platform'
          description='TwoYay connects creators and brands to create perfect matches for collabs and paid promo campaigns. Join for free today. 🤑'
          openGraph={{
            title: 'TwoYay: Influencer Marketplace & Creator Partnership Platform',
            description:
              'TwoYay connects creators and brands to create perfect matches for collabs and paid promo campaigns. Join for free today. 🤑',
            images: [
              {
                url: 'https://assets.twoyay.com/misc/logo-square.jpg',
                width: 512,
                height: 512,
                alt: 'TwoYay Influencer Marketplace',
                type: 'image/jpeg'
              }
            ],
            site_name: 'TwoYay'
          }}
          twitter={{
            handle: '@TwoYayApp',
            site: '@TwoYayApp',
            cardType: 'summary_large_image'
          }}
          additionalLinkTags={[
            {
              rel: 'icon',
              href: '/favicon.png'
            }
          ]}
        />
        <Head>
          <meta name='viewport' content='initial-scale=1.0, width=device-width' />
          {disableRobots ? <meta name='robots' content='noindex,nofollow' /> : undefined}
        </Head>
        {showAdBlockModal && <AdBlockModal />}
        <div className='relative flex flex-col min-h-screen'>
          {getLayout(<Component {...pageProps} />)}
        </div>
        <Notifications />
        {!testMode && (
          <>
            <QuantcastMeasureScript />
            <QuantcastChoiceScript />
          </>
        )}
      </QueryParamsProvider>
    </BugsnagProvider>
  )
}

export default App
