export interface SlugOption {
  name: string
  slug: string
  description?: string
}

export interface MinMaxOption {
  min?: number | null
  max?: number | null
}

export enum ErrorLevel {
  userFixableError = 'userFixableError', // 4XX BAD / FIXABLE
  criticalError = 'criticalError' // 5XX ERROR
}
