import { useEffect, useState } from 'react'

const urls = [
  'https://www3.doubleclick.net',
  'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
  'https://c.amazon-adsystem.com/aax2/apstag.js'
]

async function headRequest(url: string) {
  return await fetch(url, {
    method: 'HEAD',
    mode: 'no-cors',
    cache: 'no-store'
  })
}

async function testRequests() {
  return await Promise.all(urls.map((url) => headRequest(url)))
}

export function useDetectAdBlock() {
  const [adBlockDetected, setAdBlockDetected] = useState(false)

  useEffect(() => {
    // Try to fetch a blocked URL
    testRequests().catch(() => {
      setAdBlockDetected(true)
    })
  }, [])

  return adBlockDetected
}
