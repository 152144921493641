import { Text } from '@ui/elements/text'

import { useDetectAdBlock } from '@hooks/detect-ad-block'

import MobileLogo from '@ui/icons/mobile-logo'

import { Modal } from './modal'

function noop() {}
export function AdBlockModal() {
  const isAdBlockDetected = useDetectAdBlock()

  if (!isAdBlockDetected) {
    return null
  }
  return (
    <Modal size='xs' open={true} setOpen={noop} closeable={false}>
      <Modal.Content>
        <div className='flex flex-col items-center p-10 space-y-5'>
          <div className='flex items-center justify-center w-20 h-20 bg-gray-300 rounded-full'>
            <MobileLogo />
          </div>
          <Text size='2xl' bold>
            Please disable your ad blocker.
          </Text>

          <Text>
            TwoYay doesn&apos;t display advertisements, but some of our features do not work if
            you&apos;re using an ad blocker.
          </Text>

          <Text>
            Disable your ad blocker in your browser extensions and refresh the page to explore the
            full site.
          </Text>
        </div>
      </Modal.Content>
    </Modal>
  )
}
