export interface Upload {
  extension: string
  fileType: MimeType
  filename: string
  url: string
}

export interface MediaContent {
  url: string
  contentType: MimeType
}

export interface MediaFile extends Upload {
  parts: string[]
}

interface Multipart {
  bucket: string
  key: string
}

export interface MultipartUpload extends Multipart {
  etags: string[]
  uploadId: string
}

export interface MultipartComplete extends Multipart {
  downloadUrl: string
}

export enum MimeType {
  jpg = 'image/jpeg',
  png = 'image/png',
  gif = 'image/gif',
  svg = 'image/svg+xml',
  mp4 = 'video/mp4',
  mov = 'video/quicktime',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf = 'application/pdf',
  zip = 'application/zip'
}
