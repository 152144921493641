import { Base } from './base'

export type CampaignStatKey =
  | 'prospects'
  | 'draftProposals'
  | 'pendingProposals'
  | 'acceptedProposals'
  | 'rejectedProposals'
  | 'awaitingDeliverables'
  | 'completedProposals'

  export enum CampaignType {
    standard = 'standard',
    collaboration = 'collaboration'
  }

export interface Campaign extends Base {
  org: string
  name: string
  objective: string
  advertiser: string
  target: 'advertiser' | 'creator'
  type?: CampaignType
  status: 'active' | 'completed' | 'archived'
  stats: CampaignStats
}

export interface CampaignStats {
  prospects: number
  draftProposals: number
  pendingProposals: number
  acceptedProposals: number
  rejectedProposals: number
  completedProposals: number
  awaitingDeliverables: number
  sentProposals: number
  expiredProposals: number
  refundedProposals: number
}
