import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuthUser } from '@store/auth'
import { useSegment } from '@lib/segment'

export function usePageView() {
  const router = useRouter()
  const { segment } = useSegment()
  useEffect(() => {
    if (router.isReady && segment) {
      try {
        segment.page()
      } catch (err) {
        console.error(err)
      }
    }
  }, [router.isReady, router.pathname, segment])
}

export function useSegmentIdentification() {
  const user = useAuthUser()
  const { segment } = useSegment()
  const userId = user?.id
  useEffect(() => {
    if (user && segment) {
      segment.identify(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment, userId])
}
