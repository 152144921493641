import React, { Fragment, MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { XIcon } from '@heroicons/react/outline'
import { Text } from '@ui/elements/text'

import { useBreakpoint } from '@hooks/media-query'

export interface ModalProps {
  title?: ReactNode
  subtitle?: ReactNode
  open: boolean
  setOpen: (val: boolean) => void
  children: ReactNode
  footer?: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen'
  initialFocus?: MutableRefObject<HTMLButtonElement | HTMLInputElement | null>
  closeOnClickOutside?: boolean
  closeable?: boolean
  minimalFooter?: boolean
  whiteCloseIcon?: boolean
  containerClassName?: string
}

export function Modal({
  closeOnClickOutside = false,
  closeable = true,
  containerClassName,
  ...props
}: ModalProps) {
  /* this is a bit of a hack to fix body scrolling in safari, want to figure out a better solution */
  // UPDATE: I looked around and there aren't many better solutions for this - 9/20/22
  const scrollY = useRef<number>()
  // using our breakpoint effect hook we can at least prevent this on larger screens
  const isMobile = useBreakpoint({ size: 'sm', max: true })
  useEffect(() => {
    if (props.open && isMobile) {
      // uses a timeout so that the background scrolling up isn't seen until after the mobile modal drawer animation finishes sliding up
      setTimeout(() => {
        scrollY.current = window.scrollY
        document.body.classList.add('fixed', 'inset-0', 'overflow-hidden')
      }, 400)
      // restores the scroll position when closing the modal or growing wider than mobile
      return () => {
        document.body.classList.remove('fixed', 'inset-0', 'overflow-hidden')
        if (scrollY.current) {
          window.scrollTo(0, scrollY.current)
        }
        scrollY.current = undefined
      }
    }
  }, [isMobile, props.open])

  return (
    <Transition.Root appear show={props.open} as={Fragment}>
      <Dialog
        onTouchMove={(e: React.TouchEvent) => e.stopPropagation()}
        as='div'
        className='fixed inset-0 z-50 overflow-hidden scroll sm:overflow-y-auto'
        initialFocus={props.initialFocus}
        onClose={props.setOpen}
      >
        <div
          data-cy='modal'
          className={classNames(
            'fixed inset-y-0 justify-center text-center',
            'sm:relative sm:min-h-screen sm:top-0',
            { 'sm:px-4': props.size != 'fullscreen' }
          )}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay
              className={classNames(
                { 'pointer-events-none': !closeable || !closeOnClickOutside },
                'fixed inset-0 transition-opacity bg-black/60 dark:bg-gray-600/60'
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <Text
            as='span'
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </Text>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-400'
            enterFrom='translate-y-full sm:opacity-0 sm:translate-y-0 sm:scale-95'
            enterTo='sm:opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in-out duration-400'
            leaveFrom='sm:opacity-100 translate-y-0 sm:scale-100'
            leaveTo='sm:opacity-0 translate-y-full sm:translate-y-0 sm:scale-95'
          >
            <div
              className={classNames(
                'fixed top-8 w-full bottom-0 inset-x-0 inline-block align-bottom bg-white dark:bg-black rounded-t-2xl text-left shadow-xl transform transition-all',
                'sm:top-0 sm:inset-0 sm:relative sm:my-8 sm:align-middle sm:rounded-lg',
                {
                  'max-w-lg': props.size === 'xs',
                  'max-w-screen-sm': props.size === 'sm',
                  'max-w-screen-md': props.size === 'md' || !props.size, // default
                  'max-w-screen-lg': props.size === 'lg',
                  'sm:max-h-screen sm:h-full sm:min-w-screen sm:my-0 sm:rounded-none':
                    props.size === 'fullscreen'
                },
                containerClassName ?? ''
              )}
            >
              {closeable && (
                <div className='absolute top-0 right-0 z-20 pt-4 pr-4'>
                  <button
                    type='button'
                    className={classNames(
                      'bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 dark:ring-offset-black focus:ring-indigo-500',
                      {
                        'text-white': props.whiteCloseIcon,
                        'text-gray-400 dark:text-gray-300 dark:hover:text-gray-50 hover:text-gray-500':
                          !props.whiteCloseIcon
                      }
                    )}
                    onClick={() => props.setOpen(false)}
                  >
                    <Text as='span' className='sr-only'>
                      Close
                    </Text>
                    <XIcon className='h-7 w-7' aria-hidden='true' />
                  </button>
                </div>
              )}
              <div
                className={classNames('flex-1 h-full sm:py-0 overflow-y-auto sm:overflow-visible', {
                  'flex flex-col flex-1 !h-screen': props.size === 'fullscreen'
                })}
              >
                {props.title && <ModalHeader title={props.title} subtitle={props.subtitle} />}
                {props.children}
              </div>
              {props.footer && (
                <ModalFooter minimal={props.minimalFooter}>{props.footer}</ModalFooter>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

type ModalContentProps = {
  children: ReactNode
  className?: string
}

function ModalContent({ children, className }: ModalContentProps) {
  return (
    <div className={classNames(className, 'px-4 py-6 sm:px-6 md:pb-6 pb-[100px]')}>{children}</div>
  )
}

Modal.Content = ModalContent

type ModalFooterProps = {
  children: ReactNode
  minimal?: boolean
}

function getResolvedChildren(children: ReactNode) {
  if (!React.isValidElement(children)) return children
  return children.type === React.Fragment ? children.props.children : children
}

function ModalFooter({ children, minimal = false }: ModalFooterProps) {
  return (
    <div
      className={classNames(
        'fixed inset-x-0 bottom-0 flex items-center justify-between w-full px-4 pt-4 pb-6 space-x-3 sm:relative sm:pb-3 sm:py-3  sm:px-6 sm:justify-end',
        {
          'bg-white border-gray-300 dark:border-gray-700 rounded-b-lg border-t dark:bg-black sm:bg-gray-100 sm:shadow-none sm:dark:bg-gray-900':
            !minimal
        }
      )}
    >
      {React.Children.map(getResolvedChildren(children), (child) => {
        return React.cloneElement(child, { className: `${child.props.className} w-full sm:w-auto` })
      })}
    </div>
  )
}

Modal.Footer = ModalFooter

function ModalHeader(props: { title: ReactNode; subtitle: ReactNode }) {
  return (
    <div
      className={classNames(
        'mx-4 py-5 border-b border-gray-300',
        'sm:border-b-0 sm:shadow-none sm:pb-0 sm:relative sm:mx-6 sm:pt-5'
      )}
    >
      <div className='text-left'>
        <Dialog.Title
          as='h3'
          className='text-xl font-medium leading-6 text-gray-900 mr-7 sm:text-2xl dark:text-gray-200'
        >
          {props.title}
        </Dialog.Title>
      </div>
      {props.subtitle && (
        <Dialog.Description className='mt-3 font-medium text-left text-gray-900 mr-7 sm:text-lg dark:text-gray-400'>
          {props.subtitle}
        </Dialog.Description>
      )}
    </div>
  )
}

export function useModal(props: Omit<ModalProps, 'open' | 'setOpen'>) {
  const [open, setOpen] = useState(false)
  return {
    open,
    setOpen,
    Modal: <Modal {...props} open={open} setOpen={setOpen} />
  }
}
