import React, { ReactNode, useEffect, useRef } from 'react'
import Bugsnag, { BrowserConfig } from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { useAuthUser } from '@store/auth'
import { bugsnagToken } from './env'

export function BugsnagProvider({ children }: { children: ReactNode }) {
  const errorBoundaryRef = useRef<BugsnagErrorBoundary>()
  const user = useAuthUser()

  useEffect(() => {
    // if there's no bugsnag token then it's disabled for this environment
    if (!bugsnagToken || errorBoundaryRef.current) return
    const bugsnagOptions: BrowserConfig = {
      apiKey: bugsnagToken,
      plugins: [new BugsnagPluginReact(React)],
      onError: (event) => {
        if (!user) return false
        event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`)
        return true
      }
    }

    Bugsnag.start(bugsnagOptions)
    const bugsnagInstance = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
    errorBoundaryRef.current = bugsnagInstance
  }, [user])

  const ErrorBoundary = errorBoundaryRef.current

  return ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : <>{children}</>
}
