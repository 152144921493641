import React, { ReactNode } from 'react'
import classNames from 'classnames/dedupe'

export interface TextProps {
  as?: 'p' | 'span' | 'code' | 'h1' | 'h2' | 'h3'
  children: ReactNode
  id?: string
  className?: string
  bold?: boolean
  medBold?: boolean
  semibold?: boolean
  thin?: boolean
  italic?: boolean
  underline?: boolean
  capitalize?: boolean
  uppercase?: boolean
  center?: boolean
  left?: boolean
  right?: boolean
  xxs?: boolean
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  xl?: boolean
  xxl?: boolean
  xxxl?: boolean
  xxxxl?: boolean
  xxxxxl?: boolean
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl'
  white?: boolean
  gray?: boolean
  inverted?: boolean
  indigo?: boolean
  warning?: boolean
  danger?: boolean
  success?: boolean
  ellipsis?: boolean
  onClick?: () => void | Promise<void>
  onMouseEnter?: (e: React.MouseEvent<HTMLElement>) => void
}

export function Text({
  as: Component = 'p',
  children,
  className,
  id,
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  xxxxl,
  xxxxxl,
  size,
  bold = false,
  medBold = false,
  semibold = false,
  thin = false,
  italic = false,
  underline = false,
  capitalize = false,
  uppercase = false,
  center,
  left,
  right,
  onClick,
  onMouseEnter,
  white = false,
  gray = false,
  inverted = false,
  indigo = false,
  warning = false,
  danger = false,
  success = false,
  ellipsis = false
}: TextProps) {
  return (
    <Component
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={classNames(
        'dark:text-white',
        {
          italic,
          underline,
          capitalize,
          uppercase,
          'text-white dark:text-white': white,
          'text-gray-500 dark:text-gray-500': gray,
          'text-white dark:text-black': inverted,
          'text-indigo-600 dark:text-indigo-600': indigo,
          'text-amber-500 dark:text-amber-500': warning,
          'text-red-500 dark:text-red-500': danger,
          'text-green-500 dark:text-green-500': success,
          'font-bold': bold,
          'font-thin': thin,
          'font-medium': medBold,
          'font-semibold': semibold,
          'text-xxs': xxs || size === 'xxs',
          'text-xs': xs || size === 'xs',
          'text-sm': sm || size === 'sm',
          'text-base': md || size === 'md',
          'text-lg': lg || size === 'lg',
          'text-xl': xl || size === 'xl',
          'text-2xl': xxl || size === '2xl',
          'text-3xl': xxxl || size === '3xl',
          'text-4xl': xxxxl || size === '4xl',
          'text-5xl': xxxxxl || size === '5xl',
          'text-left': left,
          'text-center': center,
          'text-right': right,
          'cursor-pointer': !!onClick,
          'max-w-full truncate': ellipsis
        },
        className
      )}
    >
      {children}
    </Component>
  )
}
