export interface LatLngLiteral {
  lat: number
  lng: number
}

export interface AutocompleteAddress {
  formattedAddress: string
  number?: string
  street?: string
  subpremise?: string
  city?: string
  state?: string
  country?: string
  postalCode?: string
}

export interface ShippingAddress {
  name: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

export interface LocationSearchParams {
  input: string
  sessionToken: string
  type?: 'city' | 'address' | 'state' | 'postalCode'
}

export interface LocationDetailParams {
  placeId: string
  sessionToken: string
}

export interface LocationDetailResponse {
  location: LatLngLiteral
  placeId: string
  search: GeolocationDetails
}

export interface GeolocationDetails {
  latlng: LatLngLiteral
  placeId: string
  radius: number
  unit: DistanceUnit
}

export enum DistanceUnit {
  miles = 'miles',
  kilometers = 'kilometers'
}

export interface LocationPrediction {
  place_id: string
  description: string
  matched_substrings: { length: number; offset: number }[]
  reference: string
  structured_formatting: {
    main_text: string
    main_text_matched_substrings: { length: number; offset: number }[]
    secondary_text: string
  }
  terms: { offset: number; value: string }[]
  types: string[]
}

export interface LocationSearchResponse {
  predictions: LocationPrediction[]
  status: string
}
