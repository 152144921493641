import { Segment } from '@barstoolsports/web-sdk/dist'
import { useCallback, useEffect, useMemo } from 'react'
import { segmentToken } from './env'
import {
  AcceptedProposalEvent,
  AddedDeliverableEvent,
  AddedPaymentEvent,
  AddedPayoutEvent,
  AddedRateEvent,
  ConnectedChannelEvent,
  CreatedAccountEvent,
  CreatedCampaignEvent,
  CreatedOrgEvent,
  CreatedProfileEvent,
  DeletedProfileEvent,
  EmailVerifiedEvent,
  FailedPaymentEvent,
  LoginEvent,
  LogoutEvent,
  PublishedProfileEvent,
  ReceivedPaymentEvent,
  RejectedProposalEvent,
  ExpiredProposalEvent,
  RefundedProposalEvent,
  SentProposalEvent,
  SubmittedDeliverableEvent,
  InvitedMemberEvent,
  InviteAcceptedEvent,
  OpenCollaborationPostEvent,
  ApplicationEvent
} from '@api/types/segment'

let segment: Segment | null = null

export function useSegment() {
  useEffect(() => {
    segment = segment ?? new Segment(segmentToken, { proxy: 'purple.twoyay.com/v1' })
    window.segment = segment
  }, [])

  const track = useCallback(async (arg0, ...args) => {
    try {
      await segment?.track(arg0, ...args)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const events = useMemo(
    () => ({
      createdAccount: async (properties: CreatedAccountEvent) =>
        track('Account Created', properties),
      createdOrg: async (properties: CreatedOrgEvent) => track('Org Created', properties),
      invitedMember: async (properties: InvitedMemberEvent) =>
        track('Org Member Invited', properties),
      acceptedInvite: async (properties: InviteAcceptedEvent) =>
        track('Org Invite Accepted', properties),
      connectedChannel: async (properties: ConnectedChannelEvent) =>
        track('Channel Connected', properties),
      addedRate: async (properties: AddedRateEvent) => track('Rate Added', properties),
      addedPayout: async (properties: AddedPayoutEvent) => track('Payout Added', properties),
      addedPayment: async (properties: AddedPaymentEvent) => track('Payment Added', properties),
      createdProfile: async (properties: CreatedProfileEvent) =>
        track('Profile Created', properties),
      publishedProfile: async (properties: PublishedProfileEvent) =>
        track('Profile Published', properties),
      deletedProfile: async (properties: DeletedProfileEvent) =>
        track('Profile Deleted', properties),
      createdCampaign: async (properties: CreatedCampaignEvent) =>
        track('Campaign Created', properties),
      addedDeliverable: async (properties: AddedDeliverableEvent) =>
        track('Deliverable Added', properties),
      sentProposal: async (properties: SentProposalEvent) => track('Proposal Sent', properties),
      acceptedProposal: async (properties: AcceptedProposalEvent) =>
        track('Proposal Accepted', properties),
      rejectedProposal: async (properties: RejectedProposalEvent) =>
        track('Proposal Rejected', properties),
      expiredProposal: async (properties: ExpiredProposalEvent) =>
        track('Proposal Expired', properties),
      refundedProposal: async (properties: RefundedProposalEvent) =>
        track('Proposal Refunded', properties),
      submittedDeliverable: async (properties: SubmittedDeliverableEvent) =>
        track('Deliverable Submitted', properties),
      receivedPayment: async (properties: ReceivedPaymentEvent) =>
        track('Payment Received', properties),
      failedPayment: async (properties: FailedPaymentEvent) => track('Payment Failed', properties),
      loggedIn: async (properties: LoginEvent) => track('User Signed In', properties),
      loggedOut: async (properties: LogoutEvent) => track('User Signed Out', properties),
      verifiedEmail: async (properties: EmailVerifiedEvent) => track('Email Verified', properties),
      startedOpenCollaboration: async (properties: OpenCollaborationPostEvent) =>
        track('Open Collaboration Started', properties),
      publishedOpenCollaboration: async (properties: OpenCollaborationPostEvent) =>
        track('Open Collaboration Published', properties),
      closedOpenCollaboration: async (properties: OpenCollaborationPostEvent) =>
        track('Open Collaboration Closed', properties),
      editedOpenCollaboration: async (properties: OpenCollaborationPostEvent) =>
        track('Open Collaboration Edited', properties),
      approvedApplication: async (properties: ApplicationEvent) =>
        track('Application Approved', properties),
      deniedApplication: async (properties: ApplicationEvent) =>
        track('Application Denied', properties),
      submittedApplication: async (properties: ApplicationEvent) =>
        track('Application Submitted', properties)
    }),
    [track]
  )

  return { segment, events }
}
