import { useRouter } from 'next/router'
import { useMemo } from 'react'
import escapeRegExp from 'lodash/escapeRegExp'

const disabledPathPrefixes = [
  '/accept-invite',
  '/404',
  '/for',
  '/auth',
  '/oauth',
  '/onboarding/creator',
  '/onboarding/athlete',
  '/stripe',
  '/faq',
  '/privacy-policy',
  '/terms-of-use',
  '/waitlist',
  '/cookie-policy',
  '/c/[slug]',
  '/sitemaps',
  '/sitemap'
]

const disabledExactPaths = ['/onboarding', '/onboarding/org/athlete', '/onboarding/org/creator']

export function useShowAdBlockModal() {
  const { pathname, asPath } = useRouter()

  const prefixRegex = useMemo(() => {
    const search = disabledPathPrefixes.map(escapeRegExp).join('|')
    return new RegExp(`^(${search})`)
  }, [])

  const exactRegex = useMemo(() => {
    const search = disabledExactPaths.map(escapeRegExp).join('|')
    return new RegExp(`^(${search})$`)
  }, [])

  const showAdBlockModal = useMemo(() => {
    if (pathname === '/') {
      return false
    }
    return !prefixRegex.test(pathname) && !exactRegex.test(asPath)
  }, [pathname, prefixRegex, exactRegex, asPath])

  return showAdBlockModal
}
