import type { Base } from './base'
export interface UserVerification {
  email: boolean
}

export interface UserAuth {
  enabled: boolean
}

export interface UserState {
  org?: string
  advertiser?: string
  creator?: string
}

export interface UserPreferences {
  notifications: {
    email: boolean
    sms: boolean
    push: boolean
  }
}

export interface User extends Base {
  auth: UserAuth
  firstName: string
  lastName: string
  email: string
  avatar?: string | null
  verification: UserVerification
  state?: UserState
  settings: UserPreferences
}

export type UserSettings = {
  activeOrg: string | null
  activeProfile: string | null
  mode: UserMode
}

export enum UserMode {
  advertiser = 'advertiser',
  creator = 'creator'
}
