import { Base } from './base'

export enum SportsLeagueGender {
  male = 'male',
  female = 'female',
  mixed = 'mixed'
}

export interface Sport extends Base {
  name: string
  slug: string
  gender: SportsLeagueGender
}
