import { Base, Ref } from './base'
import {
  SocialConnection,
  SocialConnectionStatus,
  SocialDisplay,
  SocialKeyword,
  SocialRollup,
  SocialRollupStats,
  SocialStats
} from './social'
import { Org } from './org'
import { GeolocationDetails } from './location'
import { MinMaxOption, SlugOption } from './common'
import { College } from './college'
import { Sport } from './sport'
export type ProfileType = 'advertiser' | 'creator'

export interface Profile extends Base {
  name: string
  org: Ref<Org>
  type?: ProfileType
  rollup?: SocialRollup
  image?: string
  description?: string
  instagram?: Partial<SocialPlatformData>
  facebook?: Partial<SocialPlatformData>
  twitter?: Partial<SocialPlatformData>
  tiktok?: Partial<SocialPlatformData>
  youtube?: Partial<SocialPlatformData>
  other?: Partial<SocialPlatformData>
  genders?: SlugOption[]
  ethnicities?: SlugOption[]
  contentStyles?: SlugOption[]
}

export enum Ethnicity {
  american_indian_or_alaska_native = 'american_indian_or_alaska_native',
  asian = 'asian',
  black_or_african_american = 'black_or_african_american',
  hispanic_or_latino = 'hispanic_or_latino',
  native_hawaiian_or_other_pacific_islander = 'native_hawaiian_or_other_pacific_islander',
  white_not_hispanic_or_latino = 'white_not_hispanic_or_latino',
  prefer_not_to_respond = 'prefer_not_to_respond'
}

export enum Gender {
  female = 'female',
  male = 'male',
  gender_non_conforming = 'gender_non_conforming',
  non_binary = 'non_binary',
  transgender = 'transgender',
  prefer_not_to_respond = 'prefer_not_to_respond',
  other = 'other'
}

export enum ProfileStatus {
  active = 'active',
  archived = 'archived'
}

export interface SocialAccount extends Partial<SocialPlatformData> {
  id: SocialNetworkKey | 'other'
  name: string
}

export interface SocialPlatformData {
  socialNetworkAccount: null | string
  account: PlatformAccount
  stats?: SocialStats
  keywords: SocialKeyword[]
  display?: SocialDisplay
  supposedFollowers?: number
  deliverableOptions: Partial<Record<DeliverableOptionsKey, PlatformRate>>
  rates: PlatformRates
  status: SocialConnectionStatus
  suggestedRates?: MinMaxOption
  oauthConnection?: SocialConnection
  platformLink?: string
}

export interface PlatformRates {
  min: number | null
  max: number | null
}

export interface PlatformManagedAccount {
  image: string | null
  platformAccountId: string | null
  name: string | null
  username: string | null
}

export interface PlatformAccount {
  private?: boolean
  imageConnection: SocialConnection
  managedAccounts: PlatformManagedAccount[]
  platformAccountId: string | null
  tokens?: any[] | null
  providerId?: string
  name: string
  image: string
  username?: string
  description?: string | null
  platformLink?: string
}

export type SocialCountRange = {
  min: number
  max: number
}
export interface CampaignProspectsSearch {
  id: string
  org: string
  campaign: string
  campaignName: string
}

type SearchResultProposalStatus =
  | 'prospect'
  | 'draft'
  | 'pending'
  | 'target_accepted'
  | 'target_rejected'
  | 'awaiting_deliverables'
  | 'completed'
  | 'expired'
  | 'refunded'

export type CreatorSearchResult = {
  id: string
  creator: string
  campaignProspects?: CampaignProspectsSearch[]
  name: string
  org?: Ref<Org>
  description?: string
  followers: number
  image?: string
  rollup: SocialRollup
  stats: SocialRollupStats
  isCollegeAthlete?: boolean
  proposalId?: string
  proposalStatus?: SearchResultProposalStatus
  tiktok?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  instagram?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  twitter?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  youtube?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  facebook?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  snapchat?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
  twitch?: Pick<SocialPlatformData, 'stats' | 'platformLink'>
}

export type SocialPlatformFilter = {
  avgComments?: SocialCountRange
  avgLikes?: SocialCountRange
  avgShares?: SocialCountRange
  city?: string[]
  country?: string[]
  ethnicity?: string[]
  followers?: SocialCountRange
  following?: SocialCountRange
  language?: string[]
  totalPosts?: SocialCountRange
}

export type CreatorSearchFilters = {
  followers?: SocialCountRange
  price?: SocialCountRange
  keywords?: string[]
  instagram?: SocialPlatformFilter
  tiktok?: SocialPlatformFilter
  twitter?: SocialPlatformFilter
  youtube?: SocialPlatformFilter
}

export interface PlatformRate {
  startingRate: number | null
  blockedByCreator: boolean
  suggestedRates?: MinMaxOption
}

export enum SEARCH_TYPES {
  creator = 'creator',
  advertiser = 'advertiser'
}

export interface Histogram extends Base {
  path: string // tiktok.stats.followers
  type: SEARCH_TYPES
  min: number
  max: number
  bins: HistogramBin[]
}

export interface HistogramBin {
  min: number
  max: number
  count: number
}

export enum SocialNetwork {
  facebook = 'facebook',
  instagram = 'instagram',
  tiktok = 'tiktok',
  twitter = 'twitter',
  youtube = 'youtube'
}

export type SocialNetworkKey = keyof typeof SocialNetwork

export type SocialLinks = Partial<Record<SocialNetworkKey, string>>

export enum DeliverableOptions {
  textPost = 'textPost',
  textImagePost = 'textImagePost',
  feedPostPhoto = 'feedPostPhoto',
  feedPostVideo = 'feedPostVideo',
  textVideoPost = 'textVideoPost',
  videoPost = 'videoPost',
  feedPostCarousel = 'feedPostCarousel',
  carousel = 'carousel',
  storyPost = 'storyPost',
  reelsPost = 'reelsPost',
  youtubeShorts = 'youtubeShorts',
  other = 'other'
}

export type DeliverableOptionsKey = keyof typeof DeliverableOptions

export const CreatorStatsSchema = {
  providerId: String,
  followers: Number,
  following: Number,
  totalPosts: Number,
  avgShares: Number,
  avgLikes: Number,
  avgComments: Number,
  avgVideoViews: Number
}

export type CreatorSearchHistogramRollup = {
  price: Histogram
  followers: Histogram
} & {
  [key in SocialNetwork]: Record<keyof Omit<typeof CreatorStatsSchema, 'providerId'>, Histogram>
}

export type CreatorSearchOptions = {
  sort?: string
  campaign?: string
  advertiser: string
  twitter: SocialPlatformFilter
  instagram: SocialPlatformFilter
  tiktok: SocialPlatformFilter
  youtube: SocialPlatformFilter
  facebook: SocialPlatformFilter
  keywords: string
  demographics: {
    age?: MinMaxOption
    genders?: string[]
    ethnicities?: string[]
  }
  content: {
    styles?: string[]
  }
  interests: {
    names?: string[]
  }
  athlete: {
    sports?: Partial<Sport>[]
    divisions?: string[]
    colleges?: Partial<College>[]
  }
  price: SocialCountRange
  followers: SocialCountRange
  platforms: {
    socials?: SocialNetworkKey[]
  }
  creator_type: {
    isCollegeAthlete?: boolean
    isBarstoolAthlete?: boolean
  }
  geolocations: GeolocationDetails[]
  query: string
  input: string
}

export type CreatorSearchFormShape = Omit<
  CreatorSearchOptions,
  'demographics' | 'athlete' | 'keywords'
> & {
  age?: MinMaxOption
  genders?: string[]
  ethnicities?: string[]
  socials?: SocialNetworkKey[]
  contentStyles?: string[]
  interests?: string[]
  price?: SocialCountRange
  isCollegeAthlete?: boolean
  isBarstoolAthlete?: boolean
  colleges?: string[]
  sports?: string[]
  divisions?: string[]
  keywords: string[]
}
export interface PlatformDeliverableFieldsProps {
  postType: DeliverableOptionsKey
}
