import React from 'react'

export function MobileLogo({
  w = 60,
  h = 60,
  fill = 'white',
  className
}: {
  w?: number
  h?: number
  fill?: string
  className?: string
}) {
  return (
    <svg
      width={w}
      height={h}
      viewBox={`0 0 60 60`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M17.8287 28.2857C20.669 28.2857 22.9716 25.9832 22.9716 23.1429C22.9716 20.3025 20.669 18 17.8287 18C14.9884 18 12.6859 20.3025 12.6859 23.1429C12.6859 25.9832 14.9884 28.2857 17.8287 28.2857Z'
        fill={fill}
      />
      <path
        d='M17.8287 41.9584C20.669 41.9584 22.9716 39.6559 22.9716 36.8156C22.9716 33.9753 20.669 31.6727 17.8287 31.6727C14.9884 31.6727 12.6859 33.9753 12.6859 36.8156C12.6859 39.6559 14.9884 41.9584 17.8287 41.9584Z'
        fill={fill}
      />
      <path
        d='M36.2599 27.3714L41.7352 18.0208L47.73 18.0208L38.9923 32.7533L38.9923 42L33.4962 42L33.4962 32.7533L24.7586 18.0208L31.0962 18.0208L36.2599 27.3714Z'
        fill={fill}
      />
    </svg>
  )
}

export default MobileLogo
