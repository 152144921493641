import { useAuthStore } from '.'
import { User, UserMode, ProfileType } from '@api/types'

export function useAuthUser() {
  return useAuthStore((state) => state.user)
}

export function useAuthActiveUser(): [User | null, (user: User) => void] {
  const [activeUser, setActiveUser] = useAuthStore((state) => [state.user, state.setUser])
  return [activeUser, setActiveUser]
}

export function useMode(): [UserMode | null, (m: UserMode) => void] {
  return [useAuthStore((state) => state.settings.mode), useAuthStore((state) => state.setMode)]
}

export function useAuthActiveOrg(): [string | null, (org: string) => void] {
  const [activeOrg, setActiveOrg] = useAuthStore((state) => [
    state.settings.activeOrg,
    state.setActiveOrg
  ])
  return [activeOrg, setActiveOrg]
}

export function useAuthActiveProfile(): {
  activeProfileId: string | null
  mode: ProfileType
  setActiveProfile: (profile: string, mode: ProfileType) => void
} {
  const [activeProfileId, mode, setActiveProfile] = useAuthStore((state) => [
    state.settings.activeProfile,
    state.settings.mode,
    state.setActiveProfile
  ])
  return { activeProfileId, mode, setActiveProfile }
}
