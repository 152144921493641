import { SetState, GetState } from 'zustand'
import { User, UserCredentials, UserMode, ProfileType } from '@api/types'
import { UseAuthState } from './types'

export const state = (set: SetState<UseAuthState>, get: GetState<UseAuthState>) =>
  ({
    user: null,
    credentials: null,
    orgs: null,
    settings: {
      activeOrg: null,
      activeProfile: null,
      mode: UserMode.advertiser
    },
    isSwitchingUser: false,
    setUser: (user: User) => set({ user }),
    setCredentials: (credentials: UserCredentials) => set({ credentials }),
    setActiveOrg: (org: string) => set({ settings: { ...get().settings, activeOrg: org } }),
    setActiveProfile: (profileId: string, mode: ProfileType) =>
      set({ settings: { ...get().settings, activeProfile: profileId, mode: mode as UserMode } }),
    setMode: (mode: UserMode) => set({ settings: { ...get().settings, mode } }),
    setSettings: (settings: { activeOrg: string; activeProfile: string; mode: UserMode }) =>
      set({ settings }),
    setIsSwitchingUser: (isSwitchingUser: boolean) => set({ isSwitchingUser }),
    clear: () =>
      set({
        user: null,
        credentials: null,
        orgs: null,
        settings: { mode: UserMode.advertiser, activeOrg: null, activeProfile: null }
      })
  } as UseAuthState)
