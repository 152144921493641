import { Base } from './base'
import { PaymentStatus } from './payment-method'

export interface PayoutAccount extends Base {
  org: string
  provider: PayoutAccountProvider
  providerId: string
  default: boolean
  accounts: PayoutAccountAccount[]
}

export interface PayoutAccountAccount {
  id: string
  type: PayoutAccountType
  card?: PayoutAccountCard
  bankAccount?: PayoutAccountBankAccount
  digitalWallet?: PayoutAccountDigitalWallet
}

export enum PayoutAccountType {
  bankAccount = 'bank_account',
  card = 'card',
  digitalWallet = 'digital_wallet'
}

export enum PayoutAccountProvider {
  stripe = 'stripe',
  venmo = 'venmo'
}

export interface PayoutAccountCard {
  brand: string
  last4: string
  expiresAt: string
}

export interface PayoutAccountBankAccount {
  bankName: string
  last4: string
  routingNumber: string
}

export interface PayoutSummary {
  sentAt?: Date
  id: string
  user: string
  amount: number
  status: PaymentStatus
  org: string
  proposal: string
  provider: PayoutAccountProvider
  account?: {
    type: PayoutAccountType
    card?: {
      brand: string
      last4: string
    }
    bankAccount?: {
      bankName: string
      last4: string
    }
    digitalWallet?: {
      phoneNumber: string
    }
  }
}
export interface PayoutAccountDigitalWallet {
  phoneNumber: string
}
